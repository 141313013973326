import React from "react";

class CompanyDocumentsList extends React.Component {

    openFullSize(image) {
        switch (image) {
            case "ISOen":
                window.open("./companydocuments/ISO9001ENfull.jpg", "_blank");
                break;
            case "ISObg":
                window.open("./companydocuments/ISO9001BGfull.jpg", "_blank");
                break;
            case "bvl2024":
                window.open("./companydocuments/BVLCERTIFICATE2024full.jpg", "_blank");
                break;
            case "bvl2023":
                    window.open("./companydocuments/BVLCERTIFICATE2023full.jpg", "_blank");
                    break;
            case "bvl2022":
                window.open("./companydocuments/BVLCERTIFICATE2022full.jpg", "_blank");
                break;
            case "NSBS2024":
                    window.open("./companydocuments/NSBS2024full.jpg", "_blank");
                    break;
            case "NSBS2023":
                    window.open("./companydocuments/NSBS2023full.jpg", "_blank");
                    break;
            case "NSBS2022":
                window.open("./companydocuments/NSBS2022full.jpg", "_blank");
                break;
            case "euobshtnost":
                window.open("./companydocuments/licensefull.jpg", "_blank");
                 break;
            case "droeg":
                window.open("./companydocuments/wdroegfull2024.jpg", "_blank");
                break; 
            default:
                break;
        }
    }

    render() {
        switch (this.props.companydocuments) {
            case "en":
                this.selectedCompanyDocument = <div>
                    <div className="documents-wrapper">
                        <img className="documents-img" onClick={() => this.openFullSize('ISOen')} src="./companydocuments/thumb/ISO9001ENthumb.jpg" alt="ISO 9001:2015 Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2022')} src="./companydocuments/thumb/BVLCERTIFICATE2022thumb.jpg" alt="BVL Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2023')} src="./companydocuments/thumb/BVLCERTIFICATE2023thumb.jpg" alt="BVL Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2024')} src="./companydocuments/thumb/BVLCERTIFICATE2024thumb.jpg" alt="BVL Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2022')} src="./companydocuments/thumb/NSBS2022thumb.jpg" alt="NSBS Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2023')} src="./companydocuments/thumb/NSBS2023thumb.jpg" alt="NSBS Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2024')} src="./companydocuments/thumb/NSBS2024thumb.jpg" alt="NSBS Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('droeg')} src="./companydocuments/thumb/wdroegthumb2024.jpg" alt="W. Droege Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('euobshtnost')} src="./companydocuments/thumb/licensethumb.jpg" alt="European Community Certificate"></img>
                    </div>
                </div >

                break;

            case "de":
                this.selectedCompanyDocument = <div>
                    <div className="documents-wrapper">
                        <img className="documents-img" onClick={() => this.openFullSize('ISOen')} src="./companydocuments/thumb/ISO9001ENthumb.jpg" alt="ISO 9001:2015 Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2022')} src="./companydocuments/thumb/BVLCERTIFICATE2022thumb.jpg" alt="BVL Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2023')} src="./companydocuments/thumb/BVLCERTIFICATE2023thumb.jpg" alt="BVL Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2024')} src="./companydocuments/thumb/BVLCERTIFICATE2024thumb.jpg" alt="BVL Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2022')} src="./companydocuments/thumb/NSBS2022thumb.jpg" alt="NSBS Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2023')} src="./companydocuments/thumb/NSBS2023thumb.jpg" alt="NSBS Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2024')} src="./companydocuments/thumb/NSBS2024thumb.jpg" alt="NSBS Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('droeg')} src="./companydocuments/thumb/wdroegthumb2024.jpg" alt="W. Droege Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('euobshtnost')} src="./companydocuments/thumb/licensethumb.jpg" alt="European Community Certificate"></img>
                    </div>
                </div >

                break;

            case "bg":
                this.selectedCompanyDocument = <div>
                    <div className="documents-wrapper">
                        <img className="documents-img" onClick={() => this.openFullSize('ISObg')} src="./companydocuments/thumb/ISO9001BGthumb.jpg" alt="ISO 9001:2015 Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2022')} src="./companydocuments/thumb/BVLCERTIFICATE2022thumb.jpg" alt="BVL Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2023')} src="./companydocuments/thumb/BVLCERTIFICATE2023thumb.jpg" alt="BVL Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('bvl2024')} src="./companydocuments/thumb/BVLCERTIFICATE2024thumb.jpg" alt="BVL Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2022')} src="./companydocuments/thumb/NSBS2022thumb.jpg" alt="NSBS Certificate 2022"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2023')} src="./companydocuments/thumb/NSBS2023thumb.jpg" alt="NSBS Certificate 2023"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('NSBS2024')} src="./companydocuments/thumb/NSBS2024thumb.jpg" alt="NSBS Certificate 2024"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('droeg')} src="./companydocuments/thumb/wdroegthumb2024.jpg" alt="W. Droege Certificate"></img>
                        <img className="documents-img" onClick={() => this.openFullSize('euobshtnost')} src="./companydocuments/thumb/licensethumb.jpg" alt="European Community Certificate"></img>
                    </div>
                </div >

                break;

            default:
                break;
        }
        return this.selectedCompanyDocument;
    }
}

export default CompanyDocumentsList;