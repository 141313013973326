import React from "react";
import { FormattedMessage } from "react-intl";

class CompanyMotto extends React.Component {
    render() {
        return (
            <div className="motto-wrapper">
                <div className="company-motto">
                    <p id="motto-main-text">
                        <FormattedMessage id="Company.motto"
                            defaultMessage="“Personnel and Logistics” operates on the European continent and beyond."
                            description="Company Motto" />
                    </p>
                </div>
            </div>
        );
    }

}

export default CompanyMotto;