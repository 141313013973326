import React from "react";

class RecommendationsList extends React.Component {

    openFullSize(image) {
        switch (image) {
            case "mdEN":
                window.open("./recommendations/refMDfullEN.jpg", "_blank");
                break;
            case "mdBG":
                window.open("./recommendations/refMDfullBG.jpg", "_blank");
                break;
            case "amv":
                window.open("./recommendations/amvrosiafullDE.jpg", "_blank");
                break;
            case "feurBG":
                window.open("./recommendations/refeurocatenafullBG.jpg", "_blank");
                break;
            case "feurEN":
                window.open("./recommendations/refeurocatenafullEN.jpg", "_blank");
                break;
            case "municipalityEN":
                window.open("./recommendations/municipalityfullEN.jpg", "_blank");
                break;
            case "municipalityBG":
                window.open("./recommendations/municipalityfullBG.jpg", "_blank");
                break;
            case "medinaEN":
                window.open("./recommendations/medinafullEN.jpg", "_blank");
                break;
            case "medinaBG":
                window.open("./recommendations/medinafullBG.jpg", "_blank");
                break;
            case "margelEN":
                window.open("./recommendations/margelfullEN.jpg", "_blank");
                break;
            case "margelBG":
                window.open("./recommendations/margelfullBG.jpg", "_blank");
                break;
            case "rudiEN":
                window.open("./recommendations/rudifullEN.jpg", "_blank");
                break;
            case "rudiBG":
                window.open("./recommendations/rudifullBG.jpg", "_blank");
                break;
            case "obshtinaBLGbg":
                window.open("./recommendations/obshtinaBLGfullBG.jpg", "_blank");
                break;
            case "obshtinaBLGen":
                window.open("./recommendations/obshtinaBLGfullEN.jpg", "_blank");
                break;
            case "HARTLen":
                window.open("./recommendations/refHARTLfullEN.jpg", "_blank");
                break;
            case "HARTLbg":
                window.open("./recommendations/refHARTLfullBG.jpg", "_blank");
                break;
            default:
                break;
        }
    }

    render() {
        switch (this.props.recommendations) {
            case "en":
                this.selectedRecommendation = <div>
                    <div className="recommendations-wrapper">
                        <img className="recommendations-img" onClick={() => this.openFullSize('HARTLen')} src="./recommendations/thumb/refHARTLthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('obshtinaBLGen')} src="./recommendations/thumb/obshtinaBLGthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('feurEN')} src="./recommendations/thumb/refeurocatenathumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('mdEN')} src="./recommendations/thumb/refMDthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('municipalityEN')} src="./recommendations/thumb/municipalitythumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('medinaEN')} src="./recommendations/thumb/medinathumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('margelEN')} src="./recommendations/thumb/margelthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('rudiEN')} src="./recommendations/thumb/rudithumbEN.jpg" alt="Recommendation"></img>
                    </div>
                </div >

                break;

            case "de":
                this.selectedRecommendation = <div>
                    <div className="recommendations-wrapper">
                    <img className="recommendations-img" onClick={() => this.openFullSize('HARTLen')} src="./recommendations/thumb/refHARTLthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('obshtinaBLGen')} src="./recommendations/thumb/obshtinaBLGthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('amv')} src="./recommendations/thumb/amvrosiathumbDE.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('feurEN')} src="./recommendations/thumb/refeurocatenathumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('mdEN')} src="./recommendations/thumb/refMDthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('municipalityEN')} src="./recommendations/thumb/municipalitythumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('medinaEN')} src="./recommendations/thumb/medinathumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('margelEN')} src="./recommendations/thumb/margelthumbEN.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('rudiEN')} src="./recommendations/thumb/rudithumbEN.jpg" alt="Recommendation"></img>
                    </div>
                </div >

                break;

            case "bg":
                this.selectedRecommendation = <div>
                    <div className="recommendations-wrapper">
                    <img className="recommendations-img" onClick={() => this.openFullSize('HARTLbg')} src="./recommendations/thumb/refHARTLthumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('obshtinaBLGbg')} src="./recommendations/thumb/obshtinaBLGthumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('feurBG')} src="./recommendations/thumb/refeurocatenathumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('municipalityBG')} src="./recommendations/thumb/municipalitythumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('medinaBG')} src="./recommendations/thumb/medinathumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('mdBG')} src="./recommendations/thumb/refMDthumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('margelBG')} src="./recommendations/thumb/margelthumbBG.jpg" alt="Recommendation"></img>
                        <img className="recommendations-img" onClick={() => this.openFullSize('rudiBG')} src="./recommendations/thumb/rudithumbBG.jpg" alt="Recommendation"></img>
                    </div>
                </div >

                break;

            default:
                break;
        }
        return this.selectedRecommendation;
    }
}

export default RecommendationsList;