import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";

import { addLocaleData } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_bg from 'react-intl/locale-data/bg';

import translation_en from "./translations/en";
import translation_de from "./translations/de";
import translation_bg from "./translations/bg";

import App from "./components/App";

addLocaleData([...locale_en, ...locale_de, ...locale_bg]);

class Index extends React.Component {
    constructor() {
        super();

        this.translations = {
            en: translation_en,
            de: translation_de,
            bg: translation_bg
        }

        this.state = {
            locale: window.localStorage.getItem("locale") || "en",
            translation: this.translations.en,
            translation_stored: window.localStorage.getItem("language") || this.translations.en,
            recommendations: "en", 
            companydocuments: "en"
        }
    }

    componentDidMount(){
        this.changeLanguage(this.state.translation_stored);
    }

    changeLanguage = (language) => {
        switch (language) {
            case "en":
                window.localStorage.setItem("locale", "en");
                window.localStorage.setItem("language", "en");
                this.setState({
                    locale: "en",
                    translation: this.translations.en,
                    recommendations: "en",
                    companydocuments: "en"
                });

                break;
            case "de":
                window.localStorage.setItem("locale", "de");
                window.localStorage.setItem("language", "de");
                this.setState({
                    locale: "de",
                    translation: this.translations.de,
                    recommendations: "de",
                    companydocuments: "de"
                });

                break;
            case "bg":
                window.localStorage.setItem("locale", "bg");
                window.localStorage.setItem("language", "bg");
                this.setState({
                    locale: "bg",
                    translation: this.translations.bg,
                    recommendations: "bg",
                    companydocuments: "bg"
                });

                break;

            default:
                break;
        }
    }

    render() {
        return <IntlProvider locale={this.state.locale} messages={this.state.translation}><App languageSelection={this.changeLanguage} recommendations={this.state.recommendations} companydocuments={this.state.companydocuments}/></IntlProvider>
    }

}

ReactDOM.render(<Index />, document.querySelector("#root"));