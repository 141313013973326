import React from "react";
import { FormattedMessage } from "react-intl";

import RecommendationsList from "./RecommendationsList";
import CompanyDocumentsList from "./CompanyDocumentsList";

class PageContent extends React.Component {

    render() {
        return (
            <div className="page-content-wrapper">
                <div className="company-profile-wrapper">
                    <p id="company-profile-title">
                        <FormattedMessage id="Company.profileTitle"
                            defaultMessage="Company profile"
                            description="Company profile title" />
                    </p>
                    <p id="company-profile-text">
                        <FormattedMessage id="Company.profileTextPart1"
                            defaultMessage="“Personnel and logistics” EOOD was established in Spring of 2016 and it specializes in the transportation of goods within the countries of the European continent."
                            description="Company profile text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.profileTextPart2"
                            defaultMessage="We have vast experience in the organization and transport of FTL loads /full loads/, LTL transports /partial loads with the option to transfer goods in our warehouses located in Blagoevgrad and Sofia/, as well as transportation of goods requiring temperature control, transportation of harmless waste, personal items, etc."
                            description="Company profile text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.profileTextPart3"
                            defaultMessage="“Personnel and Logistics” EOOD is a regular member of the Bulgarian National Freight Forwarding Association "
                            description="Company profile text" />
                        <a href="https://nsbs.bg/en/team/112" target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="Company.profileTextPart4"
                                defaultMessage=" /NSBS/ "
                                description="Company profile text" />
                        </a>
                        <FormattedMessage id="Company.profileTextPart5"
                            defaultMessage="and of the German association of logistics/BVL/, which guarantees that we maintain professional work ethics and high standards of work."
                            description="Company profile text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.profileTextPart6"
                            defaultMessage="The company has a transport license for conducting international transport and it is certified according to the international standard for quality ISO 9001:2015 in the sphere of transport and freight forwarding."
                            description="Company profile text" />
                    </p>
                </div>


                <div className="successful-orders-wrapper">
                    <p ref={this.props.sectionRefs.successfulOrdersRef} id="successful-orders-title">
                        <FormattedMessage id="Company.successfulOrdersTitle"
                            defaultMessage="+10 000 Successful Orders"
                            description="Successful Orders Title" />
                    </p>
                    <p id="successful-orders-text">
                        <FormattedMessage id="Company.successfulOrdersTextPart1"
                            defaultMessage="“Personnel and Logistics” EOOD is in partnership with a great number of trusted carriers, while expanding our network of partners in Bulgaria and in Western Europe."
                            description="Successful Orders text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.successfulOrdersTextPart2"
                            defaultMessage="Our client’s security and peace of mind is our top priority. That is why we support active freight forwarder’s liability insurance (by “W.Droege & Co”) along with the mandatory CMR insurance on every truck."
                            description="Successful Orders text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.successfulOrdersTextPart3"
                            defaultMessage="The positive feedback we receive from our clients and partners motivates us to constantly expand our portfolio with new destinations and services."
                            description="Successful Orders text" />
                    </p>
                    <p className="recommendations-title">
                        <FormattedMessage id="Company.recommendationsTitle"
                            defaultMessage="Recommendations"
                            description="Recommendations title" />
                    </p>
                    <div className="recommendations-list">
                        <RecommendationsList recommendations={this.props.recommendations}></RecommendationsList>
                    </div>
                </div>

                <div className="company-values-wrapper">
                    <p ref={this.props.sectionRefs.companyValuesRef} id="company-values-title">
                        <FormattedMessage id="Company.valuesTitle"
                            defaultMessage="Company Values"
                            description="Company values title" />
                    </p>
                    <p id="company-values-text">
                        <FormattedMessage id="Company.valuesTextPart1"
                            defaultMessage="One of the main factors for our success is our correct and responsible approach towards our customers. We are always at your disposal for your inquiries in English, Bulgarian and German languages."
                            description="Company Values text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.valuesTextPart2"
                            defaultMessage="Amongst our main principles is the constant investment in expanding our expertise and skillset, new infrastructure and improving organizational workflow."
                            description="Company Values text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.valuesTextPart3"
                            defaultMessage="Our participation in specialized events such as Munich trade fair for logistics and transport allows us to expand and solidify our network of partners and customers throughout Europe."
                            description="Company Values text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.valuesTextPart4"
                            defaultMessage="We have successfully completed certified courses for road transport spedition and transport insurance, held by the Bulgarian national freight forwarding association. We continue to build on our expertise and skillset by taking part in specialized training and seminars."
                            description="Company Values text" />
                    </p>
                </div>

                <div className="company-documents-wrapper">
                    <p ref={this.props.sectionRefs.companyDocumentsRef} id="company-documents-title">
                        <FormattedMessage id="Company.documentsTitle"
                            defaultMessage="Company Documents"
                            description="Company Documents title" />
                    </p>
                    <div className="company-documents-list">
                        <CompanyDocumentsList companydocuments={this.props.companydocuments}></CompanyDocumentsList>
                    </div>
                </div>

                {/*<div className="company-history-wrapper">
                    <p ref={this.props.sectionRefs.companyHistoryRef} id="company-history-title">
                        <FormattedMessage id="Company.historyTitle"
                            defaultMessage="Company History"
                            description="Company History" />
                    </p>
                    <p id="company-history-text">
                        <FormattedMessage id="Company.historyTextPart1"
                            defaultMessage="Our loyal and accountable approach with our partners is one of the main driving forces behind our success. We are always at your disposal for your requests."
                            description="Company History text" />
                        <br />
                        <br />
                        <FormattedMessage id="Company.historyTextPart2"
                            defaultMessage="Our core values consist of constant investments in new knowledge and skills, as well as development of infrastructure and organizing our work. We have successfully completed certified courses in both automobile spedition and transport insurance, carried by the Bulgarian Association for Freight forwarding, Transport and Logistics."
                            description="Company History text" />
                    </p>
        </div>*/}


                <div className="company-contacts-wrapper">
                    <p ref={this.props.sectionRefs.contactsRef} id="company-contacts-title">
                        <FormattedMessage id="Company.contactsTitle"
                            defaultMessage="Contacts"
                            description="Company Contacts" />
                    </p>
                    <p id="company-contacts-text">
                        <FormattedMessage id="Company.contactsTextPart1"
                            defaultMessage="You can contact us in Bulgarian, English, German and Russian through the following e-mails:"
                            description="Company contacts text" />
                    </p>
                    <div id="emails-wrapper">
                        <a href="mailto:office@personnelandlogistics.eu">office@personnelandlogistics.eu</a>
                        <br />
                        <br />
                        <a href="mailto:mira@personnelandlogistics.eu">mira@personnelandlogistics.eu</a>
                        <br />
                        <br />
                        <a href="mailto:lyubomir@personnelandlogistics.eu">lyubomir@personnelandlogistics.eu</a>
                        <br />
                        <br />
                        <a href="mailto:george@personnelandlogistics.eu">george@personnelandlogistics.eu</a>
                    </div>
                    <br />
                    <div id="phone-numbers-wrapper">
                        <FormattedMessage id="Company.contactsTextPart2"
                            defaultMessage="Phone numbers:"
                            description="Telephone number text" />
                        <br />
                        <br />
                        <span itemProp="telephone"><a href="tel:+359888200947">
                            +359 888 200 947</a></span>
                        <br />
                        <br />
                        <span itemProp="telephone"><a href="tel:+359885903201">
                            +359 885 903 201</a></span>
                        <br />
                        <br />
                    </div>

                    <div id="contacts-image-wrapper">
                        <img id="contacts-photo" src="./contactsPhotoN.png" alt="Contacts"></img>
                    </div>

                    <div id="address-wrapper">
                        <div id="address-company-name">
                            <FormattedMessage id="Company.contactsAddress1"
                                defaultMessage="Personnel and Logistics EOOD"
                                description="Company Address" />
                        </div>
                        <br />
                        <div id="address-company-address">
                            <p>
                            <FormattedMessage id="Company.contactsAddress2"
                                defaultMessage="Address:"
                                description="Company Address" />
                            </p>
                            <p>
                            <FormattedMessage id="Company.contactsAddress3"
                                defaultMessage=" Bulgaria, 2700 Blagoevgrad"
                                description="Company Address" />
                            </p>
                            <p>
                            <FormattedMessage id="Company.contactsAddress4"
                                defaultMessage="Radovish street №12"
                                description="Company Address" />
                            </p>
                            <br />
                            <p>
                            <FormattedMessage id="Company.postalAddress1"
                                defaultMessage="Postal Address:"
                                description="Company Address" />
                            </p>
                            <p>
                            <FormattedMessage id="Company.postalAddress2"
                                defaultMessage="Bulgaria, 2700 Blagoevgrad"
                                description="Company Address" />
                            </p>
                            <p>
                            <FormattedMessage id="Company.postalAddress3"
                                defaultMessage="Pliska str.2, fl. 4, office 9"
                                description="Company Address" />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="organizations-logo-wrapper">
                    <img id="ISO9001-logo" src="./ISO9001.png" alt="ISO9001-logo"></img>
                    <a href="https://nsbs.bg/en/team/112" target="_blank" rel="noopener noreferrer" id="nsbs-logo-wrapper"><img id="nsbs-logo" src="./nsbs.png" alt="nsbs-logo"></img></a>
                    <img id="fiata-logo" src="./fiata.png" alt="fiata-logo"></img>
                    <a href="https://www.bvl.de/en" target="_blank" rel="noopener noreferrer" id="bvl-logo-wrapper"><img id="bvl-logo" src="./bvl.png" alt="bvl-logo"></img></a>
                </div>

                <div id="copyright">
                    <FormattedMessage id="Company.copyright"
                        defaultMessage="Copyright © “Personnel and Logistics”"
                        description="Copyright" />
                </div>
                <div className="end-of-page-space">
                </div>
            </div>
        );
    }
}

export default PageContent;